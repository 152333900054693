import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      html
    }
  }
`

const QiGong = props => {
  return (
    <Layout>
      <div className="container pb-5 pt-3">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h1 className="pt-6 pb-3 text-center">
              {props.data.markdownRemark.frontmatter.title}
            </h1>
            <span
              dangerouslySetInnerHTML={{
                __html: props.data.markdownRemark.html,
              }}
            ></span>
          </div>

          <div className="col-md-2"></div>
        </div>
      </div>
    </Layout>
  )
}

export default QiGong
